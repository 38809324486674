
      @import "variables.scss";
      @import "placeholders.scss";
      @import "mixins.scss";
    
.ecPageHome {
  padding-bottom: 56px;

  @media screen and (min-width: 1024px) {
    padding-bottom: 96px;
  }
}

.ecPageHomeTabs {
  z-index: 10;
  position: sticky;
  top: 60px;
  left: 0;
  width: 100%;
  overflow: hidden;
  background-color: $color-white;
  transition: box-shadow 0.7s;
}

.ecPageHomeTabsShadow {
  box-shadow: $shadow-to-bottom-default-shorthand;
}

.ecPageHomeTabsContainer {
  max-width: $max-width-big;
  margin: 0 auto;
}

.ecPageHomeSearch {
  max-width: $max-width-big;
  margin: 0 auto;
  padding: min($space-xxl, 5vh) $space-m 0;
  box-sizing: border-box;

  @media screen and (min-width: 1024px) {
    padding: min(56px, 5.469vh) $space-m 0;
  }
}

.ecPageHomeSection {
  max-width: $max-width-big;
  margin: 0 auto;
  padding-top: 80px;

  @media screen and (min-width: 1024px) {
    padding: 96px $space-m 0;
    box-sizing: border-box;
  }
}

.ecPageHomePartner {
  max-width: $max-width-big;
  margin: 0 auto;
  padding: 96px $space-m 0;
  box-sizing: border-box;

  @media screen and (min-width: 1024px) {
    padding: 96px $space-m 0;
    box-sizing: border-box;
  }
}

.ecPagePartnerHeroImage {
  width: 100%;
  height: 380px;
  object-fit: cover;
  object-position: bottom right;

  @media screen and (min-width: 600px) {
    object-fit: initial;
    height: auto;
  }
}
