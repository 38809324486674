
      @import "variables.scss";
      @import "placeholders.scss";
      @import "mixins.scss";
    
// Variables

// Home Section Brokers

.ecHomeBrokers {
  display: grid;
  grid-gap: $space-l;
  align-items: start;
  padding: $space-l $space-m;
}

.ecHomeBrokersVideo {
  border-radius: $border-radius-big;
  border: 0;
  margin: 0 auto;
  aspect-ratio: 16 / 9;
  width: 100%;
}

.ecServicesBannerImage {
  height: 260px;
  width: 278px;
  img {
    object-fit: contain;
  }
}
