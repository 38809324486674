
      @import "variables.scss";
      @import "placeholders.scss";
      @import "mixins.scss";
    
.ecSuggestionsContainer {
  @extend %flexcolumn;
  flex: 1 1 100%;
  background-color: $color-white;
  overflow: hidden;
  z-index: 2;

  @media screen and (min-width: 1024px) {
    position: absolute;
    width: 100%;
    margin-top: $space-xxs;
    box-shadow: $shadow-to-bottom-default-shorthand;
    border-radius: $border-radius-default;
  }
}

.ecSuggestionsScroll {
  @extend %flexcolumn;
  flex: 1 1 100%;
  overflow: auto;

  @media screen and (min-width: 1024px) {
    max-height: 312px;
  }

  &NoResults {
    flex: 0 0 auto;
  }
}

.ecSuggestionsTip {
  @extend %flexcenter;
  flex: 0 0 auto;
  gap: $space-xxxs;
  margin-top: $space-xxs;
  padding: $space-xs;
  background-color: $color-grey-100;

  @media screen and (min-width: 1024px) {
    margin-top: 0;
    border-radius: $border-radius-default $border-radius-default 0 0;
  }
}

.ecSuggestionsInfo {
  padding: 0 $space-l;
  margin-top: $space-s;
}

.ecSuggestions {
  @extend %flexcolumn;
}

.ecSuggestionItem {
  display: flex;
  align-items: center;
  gap: $space-s;
  padding: $space-s $space-l;
  background-color: $color-white;

  &:hover,
  &:focus,
  &.ecPreSelected {
    background-color: $color-grey-100;
    cursor: pointer;
    outline: none;
  }
}
