
      @import "variables.scss";
      @import "placeholders.scss";
      @import "mixins.scss";
    
.ecPageHomeSearch {
  display: grid;
  grid-gap: min($space-xl, 5vh);
}

.ecPageHomeSearchForm {
  @extend %flexcolumn;
  grid-gap: min($space-l, 5vh);

  @media screen and (min-width: 1024px) {
    flex-direction: row;
    align-items: flex-start;
    gap: $space-xs;
  }

  @media screen and (min-width: 1200px) {
    gap: $space-xxxl;
  }
}

.ecPageHomeSearchTypeahead {
  @media screen and (min-width: 1124px) {
    min-width: 362px;
  }
}

.ecPageHomeSearchTypeaheadOptions {
  @media screen and (min-width: 1024px) {
    width: 568px;
  }
}

.ecPageHomeSearchFormItem {
  @media screen and (min-width: 1024px) {
    flex: 1 1 100%;
  }
}

.ecPageHomeSearchFormButton {
  @media screen and (min-width: 1024px) {
    margin-top: $space-xxxl;
  }
}
