
      @import "variables.scss";
      @import "placeholders.scss";
      @import "mixins.scss";
    
// Variables

// Home Section Listings

.ecHomeListings {
  display: grid;
  grid-gap: $space-l;
  align-items: start;
  padding: $space-l $space-m;
}

.ecServicesBannerImage {
  height: 260px;
  width: 278px;
}
