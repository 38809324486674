
      @import "variables.scss";
      @import "placeholders.scss";
      @import "mixins.scss";
    
// Variables

$components-tabs-iconwrapper-size: 48px;

.ecTab {
  position: relative;
  display: grid;
  align-items: flex-end;
  justify-items: center;
  grid-gap: $space-xxs;
  min-height: 100%;
  padding: $space-m 0;
  box-sizing: border-box;
  font-size: $font-size-paragraph-default;
  line-height: $font-line-height-paragraph-default;
  color: var(--inactiveColor);
  white-space: nowrap;
  overflow: hidden;
  transition: color 0.5s;

  &:hover {
    color: var(--hoverColor);
  }

  @media (hover: none) {
    &:hover {
      color: var(--inactiveColor);
    }
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: var(--tabBorderColor);
    border-radius: $border-radius-default $border-radius-default 0 0;
    transform: translateY(100%);
    transition: transform 0.5s $cubic-bezier;
  }
}

.ecTabActive {
  font-weight: $font-weight-bold;
  color: var(--primaryColor);

  &::after {
    background-color: var(--primaryColor);
    transform: translateY(0);
  }
}

.ecTabSmall {
  font-size: $font-size-label-default;
  line-height: $font-line-height-label-default;
}

.ecTabImageWrapper,
.ecTabIconWrapper {
  position: relative;
  display: flex;
  justify-content: center;
  max-width: 100%;
  width: 100%;
  height: 48px;
}

.ecTabImage {
  object-fit: contain;
}

.ecTabIcon {
  @extend %flexcenter;
  width: $components-tabs-iconwrapper-size;
  height: $components-tabs-iconwrapper-size;
  background-color: var(--secondaryColor);
}
