
      @import "variables.scss";
      @import "placeholders.scss";
      @import "mixins.scss";
    
.ecPreFooter {
  display: flex;
  flex-direction: column;
  padding-top: $space-xxxxl;
  border-top: $border-default-shorthand;
  gap: $space-xxl;

  &.hideBorder {
    border: none;
  }
}
