
      @import "variables.scss";
      @import "placeholders.scss";
      @import "mixins.scss";
    
// Mixins

@mixin banner {
  @extend %flexcenter;
  padding: $space-xxl;
  border-radius: $border-radius-big;

  @media screen and (min-width: 1024px) {
    padding: $space-xg $space-g;
  }
}

// HomeBanner

.ecHomeBanner {
  @include banner;
  background-color: $color-grey-100;
}

.ecHomeBannerGreen {
  @include banner;
  background-color: $color-green-100;
}

.ecHomeBannerContainer {
  display: grid;
  gap: $space-xxl;
  width: 100%;
}
