
      @import "variables.scss";
      @import "placeholders.scss";
      @import "mixins.scss";
    
.ecList {
  margin: 0;
  padding: 0;
  list-style: none;
  border-top: $border-default-shorthand;
}

.ecItem {
  padding: 0;
  border-bottom: $border-default-shorthand;
}
