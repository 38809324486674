
      @import "variables.scss";
      @import "placeholders.scss";
      @import "mixins.scss";
    
.ecPreFooterSearchLinksContainer {
  @extend %flexcolumn;
  gap: $space-xg;
}

.ecPreFooterSearchLinksColumnsContainer {
  display: grid;
  flex-direction: column;
  gap: $space-m;

  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(var(--columns), auto);
  }
}

.ecPreFooterSearchLinksColumn {
  @extend %flexcolumn;
  gap: $space-m;
}

.ecPreFooterSearchLinksContent {
  display: grid;
  gap: $space-xxs;
  grid-auto-columns: max-content;

  @media screen and (min-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: repeat(var(--itemsPerColumn), auto);
    grid-auto-flow: column;
    justify-content: space-between;
  }
}
