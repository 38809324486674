
      @import "variables.scss";
      @import "placeholders.scss";
      @import "mixins.scss";
    
.ecTabs {
  display: inline;
  max-width: 100%;
  overflow: hidden;
}

.ecTabsNav {
  display: flex;
  align-items: center;
  gap: $space-m;
}

.ecTabsList {
  position: relative;
  flex: 0 1 100%;
  display: flex;
  flex-wrap: nowrap;
  gap: $space-xxl;
  padding: 0 $space-m;
  overflow-x: auto;
  overflow-y: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &ScrollAnimated {
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
  }

  &NoPadding {
    padding-left: 0;
    padding-right: 0;
  }
}
