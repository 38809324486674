
      @import "variables.scss";
      @import "placeholders.scss";
      @import "mixins.scss";
    
.ecHomeFAQ {
  display: grid;
  gap: $space-xg;
}

.ecHomeFAQSpaceX {
  padding: 0 $space-m;

  @media screen and (min-width: 1200px) {
    padding: 0;
  }
}

.ecHomeFAQContent {
  display: grid;
  grid-gap: $space-xg;
  align-items: start;

  @media screen and (min-width: 1024px) {
    grid-gap: $space-xg;
    grid-template-columns: 1fr 444px;
  }
}

.ecHomeFAQContainer {
  width: 100%;
  overflow: hidden;
}

.ecHomeFAQAccordionItemContent {
  padding: $space-m 0;
}
