
      @import "variables.scss";
      @import "placeholders.scss";
      @import "mixins.scss";
    
.ecServices {
  @extend %flexcolumn;
  border: 1px solid $color-grey-300;
  border-radius: 24px;
  height: 488px;

  &Big {
    height: 549px;
  }
}

.ecServicesImage {
  width: 100%;
  height: 260px;

  img {
    border-radius: $border-radius-big $border-radius-big 0 0;
    object-fit: cover;
  }
}

.ecServicesContent {
  @extend %flexcolumn;
  padding: $space-l;
  justify-content: space-between;
  gap: $space-xs;
  align-items: center;
  flex: 1;

  div {
    display: grid;
    gap: $space-xs;
  }

  h3 {
    font-size: $font-size-title-small;
  }

  p {
    font-size: $font-size-paragraph-small;
  }
}
