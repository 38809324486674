
      @import "variables.scss";
      @import "placeholders.scss";
      @import "mixins.scss";
    
// Variables

$home-aboutlistitemicon-opacity: '60';

// Home Section About

.ecHomeAbout {
  padding: 0 $space-m;
  display: grid;
  grid-gap: $space-l;
  align-items: start;

  @media screen and (min-width: 1024px) {
    grid-template-columns: 1fr 0.7fr;
  }
  @media screen and (min-width: 1200px) {
    grid-template-columns: 701px 1fr;
  }
}

.ecHomeAboutImageWrapper {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  min-height: 358px;

  @media screen and (min-width: 1024px) {
    flex: 1 1 50%;
  }

  > div {
    overflow: hidden;
    border-radius: $border-radius-big;
  }
}

.ecHomeAboutImage {
  object-fit: cover;
  object-position: center;
}

.ecHomeAboutContent {
  @extend %flexcolumn;
  align-items: start;
  gap: $space-l;
  width: 100%;
  height: 100%;
  padding: $space-xxl $space-m;
  box-sizing: border-box;
  color: $color-grey-900;
  background: $color-grey-100;
  border-radius: $border-radius-big;

  @media screen and (min-width: 1024px) {
    padding: $space-xxxxl;
    max-height: 394px;
  }
}

.ecHomeAboutTitle strong {
  color: $color-pink-900;
}

.ecHomeAboutList {
  display: grid;
  grid-gap: $space-m;
}

.ecHomeAboutListItem {
  display: flex;
  align-items: center;
  gap: $space-s;
}

.ecHomeAboutListItem + .ecHomeAboutListItem {
  padding-top: $space-m;
  border-top: 1px solid #{$color-pink-700}#{$home-aboutlistitemicon-opacity};
}

.ecHomeAboutListItemIcon {
  @extend %flexcenter;
  border-radius: 100%;
  flex: 0 0 60px;
  height: 60px;
  background-color: #{$color-pink-700}#{$home-aboutlistitemicon-opacity};
}
