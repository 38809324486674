
      @import "variables.scss";
      @import "placeholders.scss";
      @import "mixins.scss";
    
.ecCarouselItems {
  position: relative;
  order: 1;
  display: flex;
  flex-direction: row;
  gap: var(--carouselSlidesGap);
  touch-action: pan-y;
  height: 100%;
}

.ecCarouselItem {
  flex-shrink: 0;
  overflow: hidden;
  user-select: none;
  transition: var(--widthTransition);

  &.inactive {
    width: calc(var(--compressedItemWidth) - var(--carouselSlidesGap));
    max-width: calc(var(--compressedItemMaxWidth));
  }

  &.active {
    width: var(--activeItemWidth);
    max-width: 100%;
  }

  a,
  img {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
  }
}
