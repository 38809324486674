
      @import "variables.scss";
      @import "placeholders.scss";
      @import "mixins.scss";
    
// Variables

$components-faq-max-width-desktop: 464px;
$components-faq-button-dash-width: 15vw;
$components-faq-button-dash-height: 6px;
$components-faq-button-dash-max-width: 140px;
$offset-screen: 68px;

// FAQ
.ecFAQContainer {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: $z-index-listingcta;
  width: 100%;
  max-height: calc(100vh - $offset-screen);
  box-sizing: border-box;
  overflow: hidden;
  box-shadow: 0px -6px 12px rgba(33, 33, 38, 0.1);
  background: transparent;
  border-radius: $border-radius-big $border-radius-big 0 0;

  @media screen and (min-width: 768px) {
    max-height: none;
  }
}

.ecFAQ {
  background-color: $color-white;
  border: $border-default-shorthand;
  border-radius: $border-radius-big $border-radius-big 0 0;
}

.ecFAQButton {
  @extend %flexcenter;
  width: 100%;
  padding: $space-xs 0 $space-xxs;
  cursor: pointer;

  &::after {
    content: '';
    display: block;
    background-color: $color-grey-200;
    border-radius: $border-radius-default;
    width: $components-faq-button-dash-width;
    height: $components-faq-button-dash-height;
    max-width: $components-faq-button-dash-max-width;
  }
}

.ecFAQHeader {
  @extend %flexcolumn;
  align-items: center;
  padding: 0 $space-m $space-s;
  gap: $space-m;
  box-sizing: border-box;

  @media screen and (max-width: 340px) {
    padding: 0 $space-s $space-s;
  }

  @media screen and (min-width: 768px) {
    z-index: 1;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: $color-white;
    box-shadow: $shadow-to-top-default-shorthand;
  }
}

.ecFAQHeaderContainer {
  position: relative;
}

.ecFAQHeaderLink {
  display: flex;
  align-items: center;
  color: $color-grey-900;
  font-size: $font-size-paragraph-small;
  line-height: $font-line-height-label-default;
  text-decoration: underline;
}

.ecFAQHeaderLink svg {
  margin-right: $space-xxxs;
}

.ecFAQHeaderButtons {
  display: flex;
  gap: $space-xxs;
  width: 100%;

  @media screen and (min-width: 768px) {
    justify-content: center;
  }
}

.ecFAQHeaderSecondaryButton {
  flex: 0;
}

.ecFAQHeaderPrimaryButton {
  flex: 1;

  @media screen and (min-width: 768px) {
    flex: 0 0 auto;
    min-width: 188px;
  }
}

.ecFAQAccordionWrapper {
  --height: 0px;

  height: var(--height);
  width: 100%;
  transition: height 0.55s $cubic-bezier;
  will-change: height;
  overflow: auto;
}

.ecFAQAccordion {
  display: grid;
  padding: $space-m;
}

.ecFAQIconWrapper {
  @extend %flexcenter;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: $color-grey-100;
}

.ecFAQTitle {
  margin: $space-xs 0;
}

.ecFAQAccordionContainer {
  display: flex;
  flex-direction: column;
  gap: $space-s;
}

@media screen and (min-width: 768px) {
  .ecFAQContainer {
    position: initial;
    border-radius: $border-radius-big;
    box-shadow: 0px 12px 24px rgba(33, 33, 38, 0.1);
  }

  .ecFAQ {
    border-radius: $border-radius-big;
  }

  .ecFAQButton,
  .ecFAQHeaderLink {
    display: none;
  }

  .ecFAQAccordionWrapper {
    height: auto;
  }

  .ecFAQAccordion {
    padding: $space-xxl $space-l;
  }
}
