
      @import "variables.scss";
      @import "placeholders.scss";
      @import "mixins.scss";
    
// Variables

$components-form-radio-size-small: 20px;
$components-form-radio-size-medium: 24px;
$components-form-radio-size-big: 28px;
$components-form-radio-after-size-small: 10px;
$components-form-radio-after-size-medium: 14px;
$components-form-radio-after-size-big: 18px;
$components-form-radio-before-size-small: 16px;
$components-form-radio-before-size-medium: 20px;
$components-form-radio-before-size-big: 24px;
$components-form-radiobutton-height-small: 38px;
$components-form-radiobutton-height-medium: 46px;
$components-form-radiobutton-height-big: 52px;

// Mixins

@mixin radioinput {
  flex: 0 0 auto;
  position: relative;
  border: none;
  background-color: transparent;
  text-align: center;
  cursor: pointer;
  margin: 0;

  &:checked::after {
    transform: translate3d(-50%, -50%, 0) scale(1);
  }
}

@mixin radioinputbefore {
  content: '';
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  border-radius: 50%;
  border: 2px solid $color-grey-900;
  background-color: $color-white;
}

@mixin radioinputafter {
  content: '';
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  transform: translate3d(-50%, -50%, 0) scale(0);
  transition: transform 0.45s $cubic-bezier;
  background-color: $color-grey-900;
  pointer-events: none;
}

@mixin radiolabel {
  padding-left: $space-xxs;
  cursor: pointer;
}

@mixin radiolabelbutton {
  @extend %flexcenter;
  z-index: 2;
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  font-weight: $font-weight-bold;
  text-align: center;
  white-space: nowrap;
  border: $border-default-shorthand;
  border-radius: $border-radius-default;
  background-color: $color-white;
  transition: all 0.4s;
  pointer-events: none;

  .ecRadioInputButton:checked + & {
    background-color: $color-grey-100;
    border-color: $color-grey-900;
  }
}

// Radio

.ecRadio {
  display: flex;
}

// variant = 'radio'

.ecRadioInput {
  @include radioinput;
  width: $components-form-radio-size-medium;
  height: $components-form-radio-size-medium;

  &::before {
    @include radioinputbefore;
    width: $components-form-radio-before-size-medium;
    height: $components-form-radio-before-size-medium;
  }

  &::after {
    @include radioinputafter;
    width: $components-form-radio-after-size-medium;
    height: $components-form-radio-after-size-medium;
  }

  &Small {
    @include radioinput;
    width: $components-form-radio-size-small;
    height: $components-form-radio-size-small;

    &::before {
      @include radioinputbefore;
      width: $components-form-radio-before-size-small;
      height: $components-form-radio-before-size-small;
    }

    &::after {
      @include radioinputafter;
      width: $components-form-radio-after-size-small;
      height: $components-form-radio-after-size-small;
    }
  }

  &Big {
    @include radioinput;
    width: $components-form-radio-size-big;
    height: $components-form-radio-size-big;

    &::before {
      @include radioinputbefore;
      width: $components-form-radio-before-size-big;
      height: $components-form-radio-before-size-big;
    }

    &::after {
      @include radioinputafter;
      width: $components-form-radio-after-size-big;
      height: $components-form-radio-after-size-big;
    }
  }
}

.ecRadioLabel {
  @include radiolabel;
  font-size: $font-size-paragraph-default;

  .ecRadioInput:checked + & {
    font-weight: $font-weight-bold;
  }

  &Small {
    @include radiolabel;
    font-size: $font-size-paragraph-small;

    .ecRadioInputSmall:checked + & {
      font-weight: $font-weight-bold;
    }
  }

  &Big {
    @include radiolabel;
    font-size: $font-size-paragraph-big;

    .ecRadioInputBig:checked + & {
      font-weight: $font-weight-bold;
    }
  }
}

// variant = 'button'

.ecRadioButton {
  position: relative;
  height: $components-form-radiobutton-height-medium;

  &Small {
    position: relative;
    height: $components-form-radiobutton-height-small;
  }

  &Big {
    position: relative;
    height: $components-form-radiobutton-height-big;
  }
}

.ecRadioInputButton {
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  margin: 0;
  cursor: pointer;
}

.ecRadioLabelButton {
  @include radiolabelbutton;
  padding: 0 $space-s;
  font-size: $font-size-paragraph-default;

  &Small {
    @include radiolabelbutton;
    padding: 0 $space-xs;
    font-size: $font-size-paragraph-small;
  }

  &Big {
    @include radiolabelbutton;
    padding: 0 $space-m;
    font-size: $font-size-paragraph-big;
  }
}

// Others

.ecRadioGroupItems {
  display: flex;
  align-items: center;
  gap: $space-s;
  flex-wrap: wrap;
}
