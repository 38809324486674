
      @import "variables.scss";
      @import "placeholders.scss";
      @import "mixins.scss";
    
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  1%,
  90% {
    opacity: 1;
    color: $color-grey-900;
  }
  100% {
    color: $color-grey-200;
  }
}

.ecQEVPink {
  color: $color-pink-500;
}

.ecQEVModalContainer {
  overflow-x: hidden;
  overflow-y: auto;
  padding: $space-xxl $space-m;
}

.ecQEVModalContainerFullHeight {
  height: 100%;
}

.ecQEVModalContainerHasFAQ {
  padding: $space-xxl $space-m 180px;

  @media screen and (min-width: 768px) {
    padding: $space-xxl $space-m 120px;
  }
}

.ecQEVFormWrapper {
  display: grid;
  gap: $space-xxxxl;
  margin-top: $space-xxxxl;
}

.ecQEVAboutYouFormContainer {
  @media screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: $space-xxxxl;
  }
}

.ecQEVBannerError {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: $space-m;
  background: $color-red-600;
  box-shadow: 0px 12px 24px rgba(33, 33, 38, 0.3);
  border-radius: 4px;
  color: $color-white;
}

.ecQEVResult {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.ecQEVResultContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  max-width: 480px;
  width: 100%;
  height: 100%;
}

.ecQEVResultTop {
  display: grid;
  flex-direction: column;
  align-items: center;
}

.ecQEVResultBottom {
  width: 100%;
}

.ecQEVResultIconWrapper {
  @extend %flexcenter;
  justify-self: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: $color-green-100;
}

.ecQEVResultInfoContainer {
  display: flex;
  flex-direction: column;
  padding: $space-l;
  gap: $space-xs;
  background-color: $color-grey-100;
  border-radius: 8px;
  margin-bottom: $space-l;
  margin-top: $space-m;
}

.ecQEVResultTextContainer {
  text-align: center;
}

.ecQEVResultTitle {
  margin: $space-xs 0;
}

.ecQEVResultInfoItem {
  display: flex;
  align-items: center;
}

.ecQEVResultInfoItemIcon {
  display: flex;
  margin-right: $space-xs;
}

.ecQEVResultButton {
  width: 100%;
}

.ecQEVRedirect {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  gap: 60px;
}

.ecQEVRedirectText {
  animation: fadeIn 2.5s;
  animation-fill-mode: both;

  &:nth-child(2) {
    animation-delay: 2.25s;
  }

  &:nth-child(3) {
    animation-duration: 1s;
    animation-delay: 4.5s;
    color: $color-grey-900 !important;

    span {
      display: block;
      margin-bottom: $space-xs;
      color: $color-pink-500 !important;
    }
  }
}
