
      @import "variables.scss";
      @import "placeholders.scss";
      @import "mixins.scss";
    
$components-carousel-pagination-button-height: 24px;
$components-carousel-pagination-button-width: 24px;
$components-carousel-pagination-button-shape-height: 4px;
$components-carousel-pagination-button-shape-border-radius: 4px;

.ecCarouselPagination {
  margin-top: $space-xs;
  display: flex;
  flex-direction: column;
}

.ecCarouselPaginationLabel {
  margin: 0;
  color: $color-grey-500;
  font-size: $font-size-paragraph-small;
  font-weight: $font-weight-bold;
  text-align: center;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
}

.ecCarouselPaginationList {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: $space-xxs;
}

.ecCarouselPaginationButton {
  display: inline-flex;
  align-items: center;
  width: $components-carousel-pagination-button-width;
  height: $components-carousel-pagination-button-height;
  font-size: 0;

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: $components-carousel-pagination-button-shape-height;
    border-radius: $components-carousel-pagination-button-shape-border-radius;
    background: $color-grey-200;
    transition: background-color 0.2s ease;
  }

  &:disabled {
    &:before {
      background: $color-grey-500;
    }
  }
}

// Variants

.ecCarouselPaginationVariantInside {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: $space-s;
  margin-top: 0;

  .ecCarouselPaginationButton {
    &:before {
      background: rgba($color-white, 0.4);
    }

    &:disabled {
      &:before {
        background: $color-white;
      }
    }
  }

  .ecCarouselPaginationLabel {
    color: $color-white;
  }

  @media screen and (min-width: 768px) {
    bottom: $space-l;
  }
}
