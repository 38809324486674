
      @import "variables.scss";
      @import "placeholders.scss";
      @import "mixins.scss";
    
.ecItemExpanded {
  .ecContent {
    display: block;
  }

  .ecIconContainer {
    transform: rotateX(180deg);
  }
}

.ecButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  gap: $space-l;
  width: 100%;
  padding: $space-l 0;
  color: unset;
  font-weight: unset;
  text-align: left;
  transition: background 0.3s ease;

  &:hover {
    background: $color-grey-100;
  }

  &:focus {
    outline: 1px solid $color-grey-900;
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }
}

.ecContent {
  display: none;
}

.ecIconContainer {
  display: block;
  transform: rotateX(0);
  transition: transform 0.3s ease;

  svg {
    display: block;
  }
}
