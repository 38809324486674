
      @import "variables.scss";
      @import "placeholders.scss";
      @import "mixins.scss";
    
.ecHomeCities {
  display: grid;
  grid-gap: $space-m;
}

.ecHomeCitiesSpaceX {
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  padding: 0 $space-m;

  @media screen and (min-width: 1200px) {
    padding: 0;
  }
}
