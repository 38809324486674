
      @import "variables.scss";
      @import "placeholders.scss";
      @import "mixins.scss";
    
.ecList {
  display: grid;
  grid-gap: $space-xxxs;
  padding: 0;
  list-style: none;

  &Ordered {
    counter-reset: list-counter;
  }

  &Small {
    font-size: $font-size-paragraph-small;
    line-height: $font-line-height-paragraph-small;
  }

  &Medium {
    font-size: $font-size-paragraph-default;
    line-height: $font-line-height-paragraph-default;
  }

  &Big {
    font-size: $font-size-paragraph-big;
    line-height: $font-line-height-paragraph-big;
  }
}

.ecListItem {
  &::before {
    font-size: inherit;
    line-height: inherit;
  }

  &Unordered::before {
    content: '●';
    margin-right: $space-xxs;
  }

  &Ordered {
    counter-increment: list-counter;

    &::before {
      content: counter(list-counter) ' - ';
      font-weight: $font-weight-bold;
    }
  }
}
