
      @import "variables.scss";
      @import "placeholders.scss";
      @import "mixins.scss";
    
.ecCarouselItems {
  position: relative;
  order: 1;
  display: grid;
  gap: var(--carouselSlidesGap);
  grid-template-columns: repeat(
    var(--carouselItemQuantity),
    calc(var(--carouselItemWidth) + var(--carouselItemGapDiff))
  );
  touch-action: pan-y;
  height: 100%;
}

.ecCarouselItem {
  flex-shrink: 0;
  overflow: hidden;
  user-select: none;

  a,
  img {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
  }
}
