
      @import "variables.scss";
      @import "placeholders.scss";
      @import "mixins.scss";
    
.ecTypeahead {
  @extend %flexcolumn;
  width: 100%;

  &Visible {
    z-index: $z-index-modal;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;

    @media screen and (min-width: 1024px) {
      z-index: unset;
      position: relative;
      display: block;
      height: unset;
    }

    .ecTypeaheadHeader {
      display: flex;
      align-items: center;
      flex: 0 0 $header-height;
      box-sizing: border-box;
      padding: 0 $space-m 0 $space-xxxs;
      background-color: $color-white;

      @media screen and (min-width: 1024px) {
        height: unset;
        padding: 0;
      }

      > div {
        flex: 1;
      }
    }

    .ecTypeaheadBackButton {
      flex: 0 0 46px;
      display: block !important;

      @media screen and (min-width: 1024px) {
        display: none !important;
      }
    }

    .ecTypeaheadSuggestions {
      display: flex;
    }
  }
}

.ecTypeaheadHeader {
  position: relative;
  width: 100%;
}

.ecTypeaheadOpenButton {
  @extend %flexcenter;
  position: absolute;
  right: 0;
  top: 0;
  display: none;
  width: 46px;
  height: 46px;

  &Visible {
    display: flex;
    right: 0;
    top: 0;
  }

  &Modal {
    @media screen and (max-width: 1024px) {
      right: $space-m;
      top: $space-xxs - 1;
    }
  }
}

.ecTypeaheadOpenButtonIcon {
  @extend %flexcenter;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: $color-pink-500;
}

.ecTypeaheadBackButton {
  display: none !important;
}

.ecMultiselectWrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: $space-xxs;
  margin-top: $space-s;
}

.ecMultiselectWrapperHidden {
  display: none;
}

.ecTypeaheadSuggestions {
  display: none;
}
