
      @import "variables.scss";
      @import "placeholders.scss";
      @import "mixins.scss";
    
// Variables

$components-modal-max-width: 420px;
$components-modal-max-height: 720px;
$components-modal-offsetwidth: calc(100% - $space-x);
$components-modal-offsetheight: calc(100% - $space-x);
$components-modal-container-small-max-width: 320px;
$components-modal-container-big-max-width: 768px;
$components-modal-container-bigger-max-width: 980px;
$components-modal-bottom-max-height: calc(100% - $space-xg);
$components-modal-header-size: 65px;
$components-modal-closebutton-size: 48px;
$components-modal-border-color-dark: $color-grey-700;

@include fadeInKeyframe(fadeIn, 0, 1);
@include fadeInKeyframe(fadeOut, 1, 0);

@include fadeInKeyframe(fadeInBackground, 0, $opacity-default);
@include fadeInKeyframe(fadeOutBackground, $opacity-default, 0);

@include slideYKeyframe(modalSlideIn, 10vh, 0);
@include slideYKeyframe(modalSlideOut, 0, 10vh);

//  Modal

.ecModal {
  @extend %flexcenter;

  position: fixed;
  z-index: $z-index-modal;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  user-select: none;
}

.ecModalVisible {
  pointer-events: initial;
  user-select: initial;
}

.ecModalBottom {
  align-items: flex-end;

  @media screen and (min-width: 768px) {
    & {
      align-items: center;
    }
  }
}

/* Background */

.ecModalBackground {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: fadeOutBackground 0.3s linear 0.2s both;
  background: $color-grey-900;
}

.ecModalBackgroundVisible {
  animation: fadeInBackground 0.3s linear forwards;
}

// Container

.ecModalContainer {
  @extend %flexcolumn;

  position: relative;
  z-index: 2;
  width: 100%;
  max-height: $components-modal-offsetheight;
  background-color: $color-white;
  border-radius: $border-radius-default;
  overflow: hidden;
  animation: fadeOut 0.15s linear both;

  .ecModalVisible & {
    animation: fadeIn 0.15s linear 0.3s both;
  }

  @media screen and (min-width: 768px) {
    width: $components-modal-offsetwidth;
    max-width: $components-modal-max-width;
  }
}

// Theme dark

.ecModalContainerDark {
  background-color: $color-grey-800;
  color: $color-white;
}

// Width

.ecModalContainerWidthSmall {
  max-width: $components-modal-container-small-max-width;
}

.ecModalContainerWidthBig {
  max-width: $components-modal-container-big-max-width;
}

.ecModalContainerWidthBigger {
  max-width: $components-modal-container-bigger-max-width;
}

.ecModalContainerWidthFullScreen {
  max-width: initial;
  max-height: initial;
  width: 100%;
  height: 100%;
  border-radius: 0;
}

// withHeight

.ecModalContainerWithHeight {
  max-height: $components-modal-max-height;
  height: $components-modal-offsetwidth;
}

// fullHeight

.ecModalContainerFullHeight {
  @media screen and (max-width: 768px) {
    height: 100%;
    max-height: 100%;
  }
}

// bottom

.ecModalContainerBottom {
  max-height: $components-modal-bottom-max-height;
  border-radius: $border-radius-default $border-radius-default 0 0;
  animation: modalSlideOut 0.7s $cubic-bezier forwards,
    fadeOut 0.15s linear forwards;

  .ecModalVisible & {
    animation: modalSlideIn 0.7s $cubic-bezier 0.5s forwards,
      fadeIn 0.2s linear 0.5s both;
  }

  @media screen and (min-width: 768px) {
    & {
      max-height: $components-modal-offsetheight;
      border-radius: $border-radius-default;
    }
  }
}

// Header

.ecModalHeader {
  display: flex;
  justify-content: space-between;
  position: relative;
  flex: 0 0 $components-modal-header-size;
  align-items: center;
  padding: 0 $space-xxs;
  border-bottom: $border-default-shorthand;
}

.ecModalHeaderDark {
  border-color: $components-modal-border-color-dark;
}

.ecModalHeaderContent {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  min-width: $components-modal-closebutton-size;
}

.ecModalHeaderTitle {
  flex: 1;
  min-width: fit-content;
  overflow: hidden;
  text-align: center;
  white-space: nowrap;
}

// Close button

.ecModalCloseButtonFloating {
  position: absolute;
  z-index: 3;
  top: 0;
  right: 0;
}

.ecModalCloseButtonDark svg {
  path {
    color: $color-white;
  }
}

// Children Container

.ecModalChildrenContainer {
  @extend %flexcolumn;

  position: relative;
  height: 100%;
  min-height: $components-modal-header-size;
  overflow-x: hidden;
  overflow-y: auto;
}

.ecModalChildrenContainerNoScroll {
  overflow-y: hidden;
}

// Footer

.ecModalFooter {
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  gap: $space-s;
  border-top: $border-default-shorthand;
  box-shadow: $shadow-to-top-default-shorthand;
  overflow: hidden;
  box-sizing: border-box;
  padding: $space-xs $space-m;
}

.ecModalFooterDark {
  border-color: $components-modal-border-color-dark;
}

.ecModalFooterAlignStretch {
  justify-content: stretch;

  .ecModalFooterButton {
    flex: 1 1 100%;
  }
}

.ecModalFooterAlignCenter {
  justify-content: center;
}

.ecModalFooterAlignEvenly {
  justify-content: space-evenly;
}

.ecModalFooterAlignBetween {
  justify-content: space-between;
}
