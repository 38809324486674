
      @import "variables.scss";
      @import "placeholders.scss";
      @import "mixins.scss";
    
.ecTypeaheadInfo {
  strong {
    color: $color-pink-500;
  }
}
