
      @import "variables.scss";
      @import "placeholders.scss";
      @import "mixins.scss";
    
// Variables

$components-image-card-min-height-small: 200px;
$components-image-card-min-height-default: 360px;

// ImageCard

.ecImageCard {
  @extend %flexcolumn;

  z-index: 1;
  position: relative;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  min-height: $components-image-card-min-height-default;
  overflow: hidden;
  padding: 0 $space-m $space-xxl;
  box-sizing: border-box;
  border-radius: $border-radius-big;
  color: $color-white;
  background-color: $color-grey-700;

  &Small {
    min-height: $components-image-card-min-height-small;
  }

  &::after {
    content: '';

    z-index: 2;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      176.79deg,
      rgba(65, 67, 76, 0) 3.07%,
      #090a0d 97.79%
    );
    opacity: 0.8;
  }
}

.ecImageCardTitle {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  @media screen and (min-width: 1024px) {
    -webkit-line-clamp: 2;
  }
}

.ecImageCardContent {
  z-index: 3;
  display: grid;
  grid-gap: $space-xs;
}

.ecImageCardAction {
  display: flex;
  align-items: center;
  gap: $space-xxxs;
  text-decoration: underline;
}

.ecImageCardImage {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: transform 1.5s ease-in;

  .ecImageCard:hover & {
    transform: scale(1.1);
    transition-duration: 5s;
    transition-timing-function: ease-out;
  }
}

.ecImageCardImageElement {
  object-fit: cover;
}
