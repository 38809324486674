
      @import "variables.scss";
      @import "placeholders.scss";
      @import "mixins.scss";
    
.tag {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $space-xxxs $space-xs;
  background-color: $color-grey-100;
  width: fit-content;
  border-radius: 8px;
}

.closeButton {
  width: 24px;
  height: 24px;
}
