
      @import "variables.scss";
      @import "placeholders.scss";
      @import "mixins.scss";
    
$components-carousel-navigation-button-height: 40px;
$components-carousel-navigation-button-width: 40px;

.ecCarouselNavigation {
  display: flex;
  margin-bottom: $space-xs;
  justify-content: flex-end;
  gap: $space-xxs;
}

.ecCarouselNavigationBottom {
  margin-bottom: 0;
  margin-top: $space-xs;
}

// Variants

.ecCarouselNavigationVariantInside {
  margin-bottom: 0;

  .ecCarouselNavigationNext,
  .ecCarouselNavigationPrev {
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
  }

  .ecCarouselNavigationPrev {
    left: var(--left, $space-xxs);
  }

  .ecCarouselNavigationNext {
    right: var(--right, $space-xxs);
  }
}

.ecCarouselNavigationVariantOutside {
  margin-bottom: 0;

  .ecCarouselNavigationNext,
  .ecCarouselNavigationPrev {
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
  }

  .ecCarouselNavigationPrev {
    left: 0;
  }

  .ecCarouselNavigationNext {
    right: 0;
  }
}
