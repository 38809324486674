
      @import "variables.scss";
      @import "placeholders.scss";
      @import "mixins.scss";
    
// Variables

$components-form-input-height-small: 38px;
$components-form-input-height-medium: 46px;
$components-form-input-height-big: 52px;

// Input

.ecInput {
  position: relative;
  display: flex;
  gap: $space-xxs;
  height: $components-form-input-height-medium;
  padding: 0 $space-s;
  box-sizing: border-box;
  overflow: hidden;
  border: 1px solid $color-grey-500;
  border-radius: $border-radius-default;
  background-color: $color-white;

  &:focus-within {
    border-color: $color-grey-900;
  }

  &Small {
    height: $components-form-input-height-small;
  }

  &Big {
    height: $components-form-input-height-big;
  }

  &Success {
    &,
    &:focus-within {
      border-color: $color-green-500;
    }
  }

  &Error {
    &,
    &:focus-within {
      border-color: $color-red-500;
    }
  }

  &Disabled {
    cursor: not-allowed;
    user-select: none;
    border: 1px solid $color-grey-500;
    background-color: $color-grey-300;
  }
}

// Leading/Trailing

.ecInputLeadingContent,
.ecInputTrailingContent {
  @extend %flexcenter;
  flex: 0 0 auto;
  height: 100%;
}

.ecInputTrailingContent {
  gap: $space-xxs;
}

// InputField(<input /> element)

.ecInputField {
  flex: 1 1 100%;
  width: 100%; // to fix input oversize container
  height: 100%;
  padding: 0;
  font-size: $font-size-paragraph-default;
  color: $color-green-900;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: transparent;
  border: none;

  &::placeholder {
    color: $color-grey-500;
  }

  &:disabled,
  &:read-only {
    cursor: not-allowed;
    user-select: none;
  }

  &Small {
    font-size: $font-size-paragraph-small;
  }

  &Big {
    font-size: $font-size-paragraph-big;
  }

  // Chrome autocomplete
  &:-webkit-autofill,
  &:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }
  &[data-autocompleted] {
    background-color: transparent !important;
  }
}

// Clear Button

.ecInputClearButton {
  @extend %flexcenter;
  height: 100%;
}

// Text

.ecInputText {
  @extend %flexcenter;
  height: 100%;
}
