
      @import "variables.scss";
      @import "placeholders.scss";
      @import "mixins.scss";
    
.ecFormElement {
  @extend %flexcolumn;
  gap: $space-xs;
}

fieldset.ecFormElement {
  margin: 0;
  padding: 0;
  border: 0;
}

.ecFormElementTitleWrapper {
  legend {
    padding: 0;
  }
}

.ecFormElementTitleRequired {
  color: $color-pink-500;
}

.ecFormElementHelpText {
  color: $color-grey-900;
}

.ecFormElementHelpTextSuccess {
  color: $color-green-500;
}

.ecFormElementHelpTextError {
  color: $color-red-500;
}
