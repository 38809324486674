
      @import "variables.scss";
      @import "placeholders.scss";
      @import "mixins.scss";
    
.ecCarousel {
  position: relative;
  @extend %flexcolumn;
  height: 100%;

  &.ecCarouselNavigationBottom {
    flex-direction: column-reverse;
  }
}

.ecCarouselContainer {
  position: relative;
  height: 100%;
}

.ecCarouselContent {
  overflow: hidden;
  height: 100%;
}

.ecCarouselNavigation {
  order: -1;
}

// Variants

.ecCarouselNavigationVariantOutsideContainer {
  .ecCarouselContent {
    margin: 0 50px;
  }
}
