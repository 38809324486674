
      @import "variables.scss";
      @import "placeholders.scss";
      @import "mixins.scss";
    
.ecSectionHeader {
  @extend %flexcolumn;
  gap: $space-m;

  @media screen and (min-width: 1024px) {
    flex-direction: row;
    gap: 56px;
  }
}

.ecSectionHeaderItemTitle strong {
  color: $color-pink-500;
}

.ecSectionHeaderItem {
  flex: 1 1 50%;
  display: grid;
  grid-gap: $space-xxs;
}

.ecSectionHeaderButton {
  @media screen and (max-width: 1023px) {
    width: fit-content;
  }
}
